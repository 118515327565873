(() => {
  // This function will manage the click behavior for cards.
  const handleFlipCard = () => {
    let $flipCards,
      winWidth = window.innerWidth;

    // adds flippable-card__face--abs class to the faces of the flippable cards based on the height of the face
    const handleHeight = $el => {
      const $frontFace = $el.querySelector('.flippable-card__face--front');
      const $backFace = $el.querySelector('.flippable-card__face--back');
      const frontFaceHeight = $frontFace?.clientHeight || 0;
      const backFaceHeight = $backFace?.clientHeight || 0;
      $frontFace.classList.remove('flippable-card__face--abs');
      $backFace.classList.remove('flippable-card__face--abs');

      if (frontFaceHeight < backFaceHeight) {
        $frontFace.classList.add('flippable-card__face--abs');
      } else {
        $backFace.classList.add('flippable-card__face--abs');
      }
    };

    const initVariables = () => {
      $flipCards = document.querySelectorAll('.flippable-card');
    };

    const appendEvents = () => {
      $flipCards.forEach($el => {
        $el?.addEventListener('click', () => {
          $el?.classList.toggle('flippable-card--click-active');
        });

        handleHeight($el);
      });

      window.addEventListener('resize', () => {
        const curWidth = window.innerWidth;
        if (curWidth !== winWidth) {
          winWidth = curWidth;
          $flipCards.forEach($el => {
            handleHeight($el);
          });
        }
      });
    };

    initVariables();
    appendEvents();
  };

  if (document.readyState === 'loading') {
    window.addEventListener('load', handleFlipCard);
  } else {
    handleFlipCard();
  }
})();
