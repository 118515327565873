(() => {
  const handleFormSubmit = ($form: HTMLElement) => {
    let formId: string;
    let $successElement: HTMLElement;
    let $errorElement: HTMLElement;
    let $apiResponseElement: HTMLElement;
    let messagingTime = 10000;

    const initVariables = () => {
      $successElement = $form.querySelector(
        '.emu-form__message--success'
      ) as HTMLElement;
      $errorElement = $form.querySelector(
        '.emu-form__message--error'
      ) as HTMLElement;
      $apiResponseElement = $form.querySelector(
        '.emu-form__message--api-response'
      ) as HTMLElement;
      formId = $form.id;
    };

    const appendEvents = () => {
      window.Bus.on(`emu-form-${formId}:submitted`, response => {
        try {
          let responseData = response.responseData;
          const jsonObject = JSON.parse(JSON.parse(responseData)); // intentionally parsing twice to accommodate the response structure

          if (jsonObject?.status?.toLowerCase() === 'success') {
            $successElement?.classList.remove('u-hide');
          } else {
            $errorElement?.classList.remove('u-hide');
            let errorMessages = jsonObject?.errorMessage;

            if (errorMessages && errorMessages.length > 0) {
              $apiResponseElement.innerHTML = '';

              errorMessages.forEach(error => {
                $apiResponseElement.innerHTML += `<p>${error.message}</p>`;
              });

              $apiResponseElement?.classList.remove('u-hide');
            }
          }

          // making sure that the messages gets hidden after 10 seconds
          setTimeout(() => {
            $successElement?.classList.add('u-hide');
            $apiResponseElement?.classList.add('u-hide');
            $errorElement?.classList.add('u-hide');
          }, messagingTime);
        } catch (e) {
          console.warn(e);
        }
      });
    };

    initVariables();
    appendEvents();
  };
  const init = () => {
    let $formEls = document.querySelectorAll(
      '.emu-form'
    ) as NodeListOf<HTMLElement>;

    $formEls.forEach($el => {
      handleFormSubmit($el);
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
