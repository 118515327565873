(() => {
  const handleHeader = () => {
    let $header: HTMLElement,
      $mainMenuEls: NodeListOf<HTMLElement>,
      showSubmenuClass = 'show-submenu';

    const initVariables = () => {
      $header = document.querySelector('.gen-header') as HTMLElement;
      $mainMenuEls = $header?.querySelectorAll(
        '.emu-navigation__content-wrapper nav li.emu-navigation__item'
      );
    };

    const appendEvents = () => {
      // when menu element is clicked, add a class to the menu item, to show the submenu
      $mainMenuEls.forEach($el => {
        $el.addEventListener('click', () => {
          if ($el.classList.contains(showSubmenuClass)) {
            $el.classList.remove(showSubmenuClass);
          } else {
            // removing show-submenu class from any other element
            $mainMenuEls.forEach($mainMenuEl => {
              $mainMenuEl.classList.remove(showSubmenuClass);
            });
            // adding show submenu class
            $el.classList.add(showSubmenuClass);
          }
        });
      });

      // when page is scrolled, add a class to header, to add a background color
      window.addEventListener('scroll', () => {
        $header?.classList.toggle('gen-header--background', window.scrollY > 0);
      });
    };

    initVariables();
    appendEvents();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', handleHeader);
  } else {
    handleHeader();
  }
})();
