// Stylesheets
import './main.scss';

import './resources/images/favicon.png';

// Imports all component based scss files.
import './components/**/*.scss';

// Imports all component based ts files.
import './components/**/*.ts';

// import favicon here.

interface AAAEM {
  isMobile: boolean;
  isTouch: boolean;
  isAuthorMode: boolean;
  env: string;
  browser: {
    name: string;
    version: string;
  };
  // All utility functions can be found here: https://gitlab.com/venture-moonwalker/aaaem/aaaem-common/-/blob/645c2f4310c2cf0864454035850a95d28e65e68e/ui.frontend/src/global.ts
  utilities: any;
  plugins: {
    tinySlider: any;
    badgerAccordion: any;
    tabbyJs: any;
    plyr: any;
  };
}

declare global {
  interface Window {
    AAAEM: AAAEM;
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordion
  }
}

(() => {
  const handleElementVisibility = (
    selector: string,
    toggleSelector: string
  ) => {
    const $element = document.querySelector(selector) as HTMLElement;
    const $body = document.body;

    const observer = new IntersectionObserver(e => {
      $body?.classList.toggle(toggleSelector, e[0].isIntersecting);
    });

    if ($element) {
      observer.observe($element);
    }
  };

  // in rising star form, when graduation field is added, if the year added is more than 2 years, and additional field must be shown
  const handleRisingStarFormGraduationCondition = () => {
    const graduationField = document.querySelector(
      '.rising-star__enroll-form-graduation-field .emu-form-text__input'
    ) as HTMLInputElement;
    const conditionalField = document.querySelector(
      '.rising-star__enroll-form-conditional-field'
    ) as HTMLInputElement;

    graduationField.addEventListener('input', function () {
      // when date has 10 characters MM/DD/YYYY
      if (graduationField.value?.length === 10) {
        try {
          // formatter to format the date to US format MM/DD/YYYY
          const formatter = new Intl.DateTimeFormat('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });

          let inputDate = new Date(graduationField.value);
          inputDate = new Date(formatter.format(inputDate));

          let currentDate = new Date();
          currentDate = new Date(formatter.format(currentDate));

          const diffInMonths =
            (currentDate.getFullYear() - inputDate.getFullYear()) * 12 +
            (inputDate.getMonth() - currentDate.getMonth());

          if (diffInMonths > 24) {
            conditionalField.classList.remove('u-hide');
          } else {
            conditionalField.classList.add('u-hide');
          }
        } catch (e) {
          console.warn(e);
        }
      }
    });
  };

  const hideElOnSelect = (
    $select: HTMLSelectElement,
    val: string,
    $target: HTMLElement
  ) => {
    $select.addEventListener('change', () => {
      $target.classList.toggle('u-hide', $select.value !== val);
    });
  };

  const init = () => {
    const $risingStar = document.getElementById('rising-star');
    const $specialityField = document.querySelector(
      '.gen-form__form-speciality-field .emu-form-dropdown__select'
    ) as HTMLSelectElement;
    const $otherField = document.querySelector(
      '.gen-form__form-other-field'
    ) as HTMLElement;

    hideElOnSelect($specialityField, 'other', $otherField);

    if ($risingStar) {
      handleRisingStarFormGraduationCondition();
    }

    if (window.Bus) {
    }

    handleElementVisibility('footer.experiencefragment', 'footer-in-view');
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
