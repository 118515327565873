(() => {
  const handleCarousels = () => {
    let $carouselEls: NodeListOf<HTMLElement>,
      $tnsCarousel: HTMLElement,
      $carouselEmbedEls: NodeListOf<HTMLElement>;

    // adds an active class to the current slide and the navigation dot, making it possible to add styles to them
    const handleSlideChange = () => {
      if ($carouselEls.length) {
        $carouselEls.forEach(carousel => {
          const carouselId = carousel.id;
          const curInst = window._tnsInstances[carouselId];

          try {
            curInst.getInfo().slideItems[0].classList.add('js-slide-active');
            curInst.getInfo()?.navItems[0].classList.add('js-nav-active');
          } catch (e) {
            console.warn(e);
          }

          curInst?.events?.on('indexChanged', info => {
            if (info?.slideItems?.length) {
              const $slides = Array.from(info?.slideItems) as HTMLElement[];
              const $navItems = Array.from(info?.navItems) as HTMLElement[];
              try {
                $slides.forEach(($slide, i) => {
                  $slide.classList.toggle(
                    'js-slide-active',
                    info.displayIndex - 1 === i
                  );
                });

                $navItems.forEach(($slide, i) => {
                  $slide.classList.toggle(
                    'js-nav-active',
                    info.displayIndex - 1 === i
                  );
                });
              } catch (e) {
                carousel.querySelectorAll('.tns-item').forEach($el => {
                  $el.classList.add('js-slide-active');
                });
                console.warn(e);
              }
            }
          });
        });
      }
    };

    $carouselEmbedEls = document.querySelectorAll(
      '.testimonial__carousel .embed'
    );

    $tnsCarousel = document.querySelector(
      '.testimonial__carousel .tns-carousel'
    ) as HTMLElement;

    $carouselEmbedEls.forEach(embed => {
      embed.addEventListener('ready', event => {
        const player = (event as CustomEvent).detail.plyr;

        player.on('enterfullscreen', () => {
          if (player.id) {
            $tnsCarousel?.classList.add('transform-none');
          }
        });

        player.on('exitfullscreen', () => {
          if (player.id) {
            $tnsCarousel?.classList.remove('transform-none');
          }
        });
      });
    });

    const initVariables = () => {
      $carouselEls = document.querySelectorAll(
        '.emu-carousel'
      ) as NodeListOf<HTMLElement>;
    };

    const init = () => {
      handleSlideChange();
    };

    initVariables();
    init();
  };
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', handleCarousels);
  } else {
    handleCarousels();
  }
})();
